<template>
    <form class="space-y-2">
        <div class="h-14 border rounded-lg overflow-hidden flex">
            <PriceInput class="w-1/2 h-full px-2 focus:outline-none" type="text" :placeholder="$t('landing_pages.buy_house_in_country.search_box.min_price')" v-model="minPrice" />

            <div class="w-1 h-full py-2">
                <div class="border-l w-full h-full"></div>
            </div>

            <PriceInput class="w-1/2 h-full px-2 focus:outline-none" type="text" :placeholder="$t('landing_pages.buy_house_in_country.search_box.max_price')" v-model="maxPrice" />
        </div>

        <input type="hidden" name="price" v-model="priceValue" />
        <input type="hidden" name="country" v-model="$props.country.iso_code_2" />

        <button class="bg-primary text-white w-full rounded-lg font-bold flex items-center justify-center space-x-3 h-14">
            <i class="fi fi-rr-search flex items-center"></i>
            <div class="md:block hidden">{{ $t('landing_pages.buy_house_in_country.search_box.search') }}</div>
        </button>
    </form>
</template>

<script>
	import { ref } from 'vue'
    import PriceInput from './PriceInput.vue'

	export default {
	    props: ['country'],
        components: {
            PriceInput
        },
        watch: {
            minPrice(value) {
                this.priceValue = value

                if(this.maxPrice) {
                    this.priceValue = `${ this.minPrice ?? '' }-${ this.maxPrice ?? '' }`
                }
            },
            maxPrice(value) {
                this.priceValue = `${ this.minPrice ?? '' }-${ value }`

                if(!value) {
                    this.priceValue = `${ this.minPrice ?? '' }`
                }
            }
        },
	    setup(props) {
            const minPrice = ref(null)
            const maxPrice = ref(null)

            const priceValue = ref(null)

            return {
                minPrice,
                maxPrice,
                priceValue
            }
		}
	}
</script>
