<template>
    <div class="flex flex-col items-center justify-center relative">
        <div v-if="listingIsSelected" class="absolute w-72 overflow-hidden bg-white rounded-md shadow-md bottom-10">
            <a :href="listing.url" target="_blank">
                <div v-if="(dayjs().diff(listing.created_at, 'day') < 5) && listing.status.name == 'available'" class="bg-primary text-white p-1 rounded text-xs shadow-xl absolute inline-block top-2 left-2">New Listing</div>
                <div v-if="listing.status.name != 'available'" class="p-1 rounded text-xs shadow-xl text-white absolute inline-block top-2 left-2" :style="`background-color:${ listing.status.color }`">{{ $t(`statuses.${ listing.status.name }`) }}</div>

                <img v-if="listing.photos.length > 0" :src="listing.photos[0].image_url" class="w-full object-cover" />
                <img v-else src="/images/blank_image.png" class="w-full object-cover" />

                <div class="p-3">
                    <h1 class="font-semibold text-[16px] font-sans">{{ listing.title }}</h1>
                </div>
            </a>
        </div>

        <div class="rounded-lg py-2 px-2 border shadow-lg text-[14px] font-semibold flex items-center space-x-2 hover:scale-110 transition ease-in-out duration-300" v-bind:class="{'bg-gray-800 text-white': listingIsSelected, 'bg-white': !listingIsSelected }">
            <i class="fi fi-rr-home-heart flex items-center" v-bind:class="{'text-white': listingIsSelected, 'text-primary': !listingIsSelected}"></i>
            <span class="block">{{ listing.price.display_price }}</span>
        </div>
    </div>
</template>


<script>
	import { ref, inject } from 'vue'
    import dayjs from 'dayjs'

    export default {
	    props: ['listing', 'listingIsSelected'],
        components: {

        },
	    setup(props) {

            return {
                dayjs
            }
		}
	}
</script>
